import { useCallback, useEffect } from 'react';
import { ONLINE } from '@/utils/constants';
import useThrottle from './useThrottle';

/**
 * 监听网络连接和页面可见性变化的 Hook
 * @param callback 当网络连接或页面变为可见时要执行的回调函数
 */
const useNetworkAndVisibility = (callback: () => void) => {
  const throttledCallback = useThrottle(callback, 10000);

  const handleVisible = useCallback(() => {
    if (document.visibilityState === 'visible') {
      throttledCallback();
    }
  }, [throttledCallback]);

  useEffect(() => {
    // 监听网络连接
    window.addEventListener(ONLINE, callback);

    // 监听页面可见性
    document.addEventListener('visibilitychange', handleVisible);

    return () => {
      window.removeEventListener(ONLINE, callback);
      document.removeEventListener('visibilitychange', handleVisible);
    };
  }, [handleVisible, callback]);
};

export default useNetworkAndVisibility;
